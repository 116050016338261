.page-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background: rgb(29,23,54);
    background: linear-gradient(45deg, #13151b 0%, #242e3a 100%);
    color: white;
}

p {
    font-size: 20px;
}
