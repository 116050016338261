* {
  font-family: 'Open Sans', sans-serif;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
  background: #EB5E28 !important;
  width: 8px !important;
  height: 8px !important;
  margin: -12px 0 0 -12px !important;
}

#fp-nav ul li:hover a.active span, #fp-nav ul li a.active span, .fp-slidesNav ul li:hover a.active span, .fp-slidesNav ul li a.active span {
  width: 16px !important;
  height: 16px !important;
  margin: -16px 0 0 -16px !important;
}